import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';  // CSS for Navbar styling

const Navbar = () => {
  return (
    <header className="header">
      <div className="header-left">
        <Link to="/">
          <img src="Amazantian Logo.png" alt="Company Logo" className="logo" />
        </Link>
      </div>
      <div className="header-right">
        <span className="email">Email: Amazantian@gmail.com</span>
        <span className="phone">Phone: (000) 000-0000</span>
      </div>
      <nav className="nav-bar">
        <div className="nav-buttons">
          <Link to="/find-talent">
            <button className="find-talent-btn">Find Talent</button>
          </Link>
          <Link to="/find-jobs">
            <button className="find-jobs-btn">Find Jobs</button>
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
